import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { Box, Container } from "@mui/material";
import Header from "../components/_shared/_navigation/Header";
import DarkModeToggleButton from "../components/_shared/DarkModeToggleButton";
import Footer from "../components/_shared/_footer";


const heightHeader = 80
const widthColorButton = heightHeader / 2

const styleSxWrap = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  overflowX: "hidden",
  position: "relative",
  bgcolor: "primary2.main",
  color: "primary2.contrastText",

  "& .templContHeader": {
    display: "flex",
    alignItems: "center",
  },
  "& .templContFoot": {
    position: "relative",
    color: "primary2.contrastText",
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
    my: 0,
  },
  "& .main": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
  }
}

function NotFound(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });

  return (
    <Box
      sx={styleSxWrap}
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
      }}>
      <Container
        maxWidth={false}
        className="templContHeader"
        key="cont-header"
        style={{
          height: `${heightHeader}px`,
        }}
      >
        <DarkModeToggleButton />
        <Header heightHeader={heightHeader} leftShift={widthColorButton}
          isDesktop={isDesktop} />
      </Container>
      <section
        className="main"
      >
        <p>Ah, nenašli...</p>
        <p >
          {`Taká stránka ${props.location.href} neexistuje...`}
        </p>
      </section>

      <Container
        maxWidth={false}
        className="templContFoot"
      >
        <Footer />
      </Container>

    </Box>
  );
}

export default NotFound;
